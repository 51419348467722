
import { Component } from "vue-property-decorator";
import HospitalLayout from "@/components/layouts/HospitalLayout.vue";
import HospitalEditForm from "@/components/forms/HospitalEditForm.vue";
import Button from "@/components/Button.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";

@Component({
  components: {
    HospitalEditForm,
    HospitalLayout,
    Button,
  },
})
export default class HospitalDetail extends CheckCommonLogin {}
